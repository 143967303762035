type State = Record<string, any>;
const initialState = () =>
  ({
    isDarkTheme: true,
    strictRegEx: /[&?/#<>"'=;()]/,
    maxChars: 25,
  } as State);

export default {
  state: initialState,
  mutations: {
    setValue(state: State, [key, value]: [string, any]) {
      state[key] = value;
    },
    reset(state: State) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
  },
};
