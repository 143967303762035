


























import Vue from "vue";
//@ts-ignore
import { Map, TileLayer } from "maptalks";
import { mapState } from "vuex";

export default Vue.extend({
  name: "MaptalksCanvas",
  props: {
    mapObject: Object,
    mapView: Object,
    options: Object,
    height: String,
    width: String,
    centerCross: Boolean,
    centerByClick: Boolean,
  },
  data: () => ({
    urlTemplateLight:
      "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png",
    urlTemplateDark:
      "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png",
    isClickedAndHolded: true,
    baseTileLayer: {} as any,
    localMapObject: {} as any,
  }),
  computed: {
    ...mapState(["isDarkTheme"]),
  },
  watch: {
    isDarkTheme(val) {
      if (val) {
        this.baseTileLayer.setOptions({
          urlTemplate: this.urlTemplateDark,
        });
      } else {
        this.baseTileLayer.setOptions({ urlTemplate: this.urlTemplateLight });
      }
      this.baseTileLayer.clear();
      this.localMapObject.setZoom(this.localMapObject.getZoom() - 0.001);
    },
    async selectedMode(val) {
      if (val === "canvas") {
        this.localMapObject.setPitch(0);
        this.localMapObject.config("dragPitch", false);
        this.localMapObject.config("touchPitch", false);
      } else {
        this.localMapObject.setPitch(45);
        this.localMapObject.config("dragPitch", true);
        this.localMapObject.config("touchPitch", true);
      }
      this.localMapObject.removeLayer("base");
      this.baseTileLayer = this.makeTileLayer();
      this.localMapObject.addLayer(this.baseTileLayer);
    },
  },
  created() {
    window.addEventListener("keydown", (e) => {
      if (e.key === "+") {
        this.zoomIn();
      }
      if (e.key === "-") {
        this.zoomOut();
      }
    });
  },
  methods: {
    zoomIn() {
      this.localMapObject.zoomIn();
    },
    zoomOut() {
      this.localMapObject.zoomOut();
    },
    makeTileLayer() {
      const layer = new TileLayer("base", {
        urlTemplate: this.isDarkTheme
          ? this.urlTemplateDark
          : this.urlTemplateLight,
        subdomains: ["a", "b", "c", "d"],
        maxAvailableZoom: 20,
        maxZoom: 24,
        hitDetect: false,
        forceRenderOnZooming: true,
        forceRenderOnMoving: true,
        forceRenderOnRotating: true,
        renderer: "gl",
      });
      layer.getTileUrl = function(x: number, y: number, z: number) {
        return TileLayer.prototype.getTileUrl.call(this, x, y, z);
      };
      return layer;
    },
  },

  mounted() {
    const resolutions = [];
    const d = 2 * 6378137 * Math.PI;
    for (let i = 0; i < 24 + 1; i++) {
      resolutions[i] = d / (256 * Math.pow(2, i));
    }
    this.baseTileLayer = this.makeTileLayer();
    const { center, zoom, pitch, bearing } = this.mapView;
    this.localMapObject = new Map(`${this._uid}_map-container`, {
      center,
      pitch: [undefined, null, false].includes(pitch) ? 45 : pitch,
      zoom: zoom || 14,
      bearing: bearing || 0,
      maxPitch: 65,
      centerCross: this.centerCross,
      spatialReference: {
        projection: "EPSG:3857",
        resolutions: resolutions,
        fullExtent: {
          top: 6378137 * Math.PI,
          left: -6378137 * Math.PI,
          bottom: -6378137 * Math.PI,
          right: 6378137 * Math.PI,
        },
      },
      minZoom: 5,
      maxZoom: 24,
      doubleClickZoom: false,
    });
    this.localMapObject.addLayer(this.baseTileLayer);
    this.localMapObject.off("dblclick");
    this.$emit("update:mapObject", this.localMapObject);
    if (this.centerByClick) {
      this.localMapObject.on("click", (center: any) => {
        const currentView = this.localMapObject.getView();
        this.localMapObject.animateTo(
          { ...currentView, center: center.coordinate },
          {
            duration: 200,
          }
        );
      });
    }
    this.localMapObject.on("viewchange", () => {
      this.$emit("update:mapView", this.localMapObject.getView());
      this.$emit("viewChange", this.localMapObject.getCenter());
    });
  },
});
