export const insensitify = (str: string) =>
  str
    .split("")
    .map((char) => `[${char.toLowerCase()}${char.toUpperCase()}]`)
    .join("");

export const coordinatesToLocation = (coordinates: [number, number]) => {
  return `${coordinates[1].toFixed(8)}, ${coordinates[0].toFixed(8)}`;
};

const timers = {} as any;
export const debounce = (
  callback: Function,
  timeout: number,
  key: string = "main"
) => {
  clearTimeout(timers[key]);
  timers[key] = setTimeout(callback, timeout);
};

export const unbreakable = (str: string) => {
  const maxStrLength = 20;
  let unbreakableStr = str;
  if (unbreakableStr.length > maxStrLength) {
    unbreakableStr = unbreakableStr.slice(0, maxStrLength) + "...";
  } else {
    unbreakableStr = str.replace(" ", "\xa0").replace("-", "\u{2011}");
  }
  return unbreakableStr;
};

export const coordinatesToPosition = (coordinates: [number, number]) => {
  return { lng: coordinates[0], lat: coordinates[1] };
};
export const uuid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    const r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};
export const locationToCoordinates = (
  location: string
): [number, number] | null => {
  try {
    return location
      ? [
          Number.parseFloat(location.split(", ")[1]),
          Number.parseFloat(location.split(", ")[0]),
        ]
      : null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const findRealParent = (firstVueParent: any) => {
  let found = false;
  while (firstVueParent && !found) {
    if (firstVueParent.mapObject === undefined) {
      firstVueParent = firstVueParent.$parent;
    } else {
      found = true;
    }
  }
  return firstVueParent.mapObject;
};

export const findParentLayer = (firstVueParent: any) => {
  let found = false;
  while (firstVueParent && !found) {
    if (firstVueParent.layerComponent === undefined) {
      firstVueParent = firstVueParent.$parent;
    } else {
      found = true;
    }
  }
  return firstVueParent.layerComponent;
};
