




































import Vue from "vue";
import { mapState } from "vuex";
import InputMask from "primevue/inputmask";

export default Vue.extend({
  name: "AddressField",
  components: {
    InputMask,
  },
  props: {
    entity: Object,
    options: Object,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["strictRegEx"]),
    localValue: {
      set(val: Record<string, string>) {
        const entity = { ...this.entity };
        entity[this.options.title] = val;
        this.$emit("commit", entity);
      },
      get(): Record<string, string> {
        return this.entity[this.options.title] || {};
      },
    },
  },
  mounted() {
    if (!this.entity[this.options.title]) {
      this.entity[this.options.title] = {
        addressLocality: "",
        streetAddress: "",
        postalCode: undefined,
      };
    }
  },
});
