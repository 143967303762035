






import Vue from "vue";
export default Vue.extend({
  name: "Empty",
  props: {
    message: String,
  },
});
