










import Vue from "vue";
import { debounce } from "@/utils";
import { mapState } from "vuex";
export default Vue.extend({
  name: "InputField",
  props: {
    entity: Object,
    options: Object,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["strictRegEx"]),
    localValue: {
      set(val: string) {
        debounce(
          () => {
            const entity = { ...this.entity };
            entity[this.options.title] = val
              .replace(this.strictRegEx, "")
              .trim();
            this.$emit("commit", entity);
          },
          500,
          `${this._uid}_input`
        );
      },
      get(): String {
        return String(this.entity[this.options.title] || "");
      },
    },
  },
});
