var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"marker-container"},[_c('div',{staticClass:"marker__circle-background",style:({
      backgroundColor: _vm.color,
      opacity: _vm.isDarkTheme ? '0.7' : '1',
      boxShadow: _vm.isDarkTheme ? ("0px 0px 15px 8px " + _vm.color) : 'none',
    })}),_c('div',{staticClass:"marker__circle",style:({
      border: ("1px solid " + _vm.color),
    })},[_vm._t("default")],2),(_vm.haveLeg)?_c('div',{staticClass:"marker__leg",style:(("background-image: linear-gradient(to bottom, " + _vm.color + ", rgba(255,255,255,0));"))}):_vm._e(),(_vm.haveLeg)?_c('div',{staticClass:"marker__pointer"},[_c('div',{staticClass:"marker__shadow",style:({
        backgroundColor: _vm.color,
        opacity: '0.4',
      })}),_c('div',{staticClass:"marker__dot",style:({
        backgroundColor: _vm.color,
      })})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }