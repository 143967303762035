







import Vue from "vue";
import MarkerCircle from "./MarkerCircle.vue";

export default Vue.extend({
  name: "IconMarker",
  props: {
    field: Object,
  },
  components: {
    MarkerCircle,
  },
  data() {
    return {};
  },
});
