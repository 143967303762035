









































































































































































































import Vue from "vue";
// @ts-ignore
import { fiwareOptions, gatewayPath } from "@netvision/lib-api-gateway";
import IconField from "./fields/IconField.vue";
import IconMarker from "./fields/IconMarker.vue";
import AddressText from "./fields/AddressText.vue";
import AddressField from "./fields/AddressField.vue";
import CoordinatesField from "./fields/CoordinatesField.vue";
import InputField from "./fields/InputField.vue";
import TextField from "./fields/TextField.vue";
import { createCamerasConnection } from "@netvision/lib-api-gateway";
import Empty from "./Empty.vue";

type Statuses = "fulfilled" | "pending" | "rejected";

export default Vue.extend({
  name: "ImportCameras",
  components: {
    IconMarker,
    Empty,
  },
  props: {
    forceLoad: Function,
    defaultCameraType: {
      type: String,
      default: "tbo",
    },
  },
  data() {
    return {
      editingRows: [],
      showDialog: false,
      providers: ["Loading"] as string[],
      selected: [] as Camera[],
      entities: {} as Record<string, Camera[]>,
      currentProvider: "",
      loading: true,
      InputField,
      showImpordResultsDialog: false,
      originalRows: [] as any[],
      needToForceLoad: false,
      importedPromises: [] as { entity: Camera; status: Statuses }[],
    };
  },
  watch: {
    async currentProvider(val) {
      if (
        this.entities[val] === undefined ||
        (Array.isArray(this.entities[val]) && this.entities[val].length > 0)
      ) {
        this.loading = true;
        try {
          await this.fetchCameras();
        } catch (error) {
          this.entities[val] = [];
          console.error(error);
        }
        this.loading = false;
      }
    },
    async showDialog(val) {
      if (val) {
        this.selected = [];
        await this.fetchProviders();
        if (this.providers.length > 0) this.currentProvider = this.providers[0];
      } else {
        this.selected = [];
        this.providers = [];
        this.entities = {};
        this.currentProvider = "";
      }
    },
  },
  computed: {
    columns(): any {
      return [
        {
          field: "title",
          header: this.$t("headers.title"),
          editorComponent: TextField,
        },
        {
          field: "address",
          header: this.$t("headers.address"),
          bodyComponent: AddressText,
          editorComponent: AddressField,
        },
        {
          field: "location",
          header: this.$t("headers.location"),
          editorComponent: CoordinatesField,
        },
        {
          field: "cameraType",
          header: this.$t("headers.cameraType"),
        },
        {
          field: "icon",
          header: this.$t("headers.icon"),
          headerStyle: "width: 14rem;",
          bodyComponent: IconMarker,
          editorComponent: IconField,
        },
      ];
    },
    importComplete(): Boolean {
      return (
        this.importedPromises.length === 0 ||
        this.importedPromises.every(({ status }) => status !== "pending")
      );
    },
  },
  methods: {
    closeImportDialog() {
      this.entities[this.currentProvider] = [];
      this.showImpordResultsDialog = false;
      this.selected = [];
      this.needToForceLoad = true;
      this.fetchCameras();
    },
    onRowEditInit(event: any) {
      this.originalRows[event.index] = JSON.parse(JSON.stringify(event.data));
    },
    onRowEditCancel(event: any) {
      const entities = { ...this.entities };
      entities[this.currentProvider][event.index] = this.originalRows[
        event.index
      ];
      this.entities = entities;
    },
    importSelected() {
      this.showImpordResultsDialog = true;
      this.importedPromises = this.selected.map((entity: Camera) => {
        const result = {
          entity,
          status: "pending" as Statuses,
        };
        entity.id = entity.id || "";
        createCamerasConnection()
          .v2.createEntity(entity, {
            keyValues: true,
          })
          .then(() => {
            result.status = "fulfilled";
          })
          .catch(() => {
            result.status = "rejected";
          });
        return result;
      });
    },
    async fetchProviders() {
      const response = await fetch(gatewayPath + "/vms/v1/providers", {
        headers: {
          "Fiware-Service": fiwareOptions.service,
          "Fiware-ServicePath": fiwareOptions.servicePath,
        },
      });
      const providers = await response.json();
      this.providers = providers.map(({ name }: any) => name);
    },
    async fetchCameras() {
      this.loading = true;
      const provider = this.currentProvider;
      try {
        const results = await fetch(
          gatewayPath +
            `/vms/v1/providers/${provider}/cameras?cameraExistence=NotExisted`,
          {
            headers: {
              "Fiware-Service": fiwareOptions.service,
              "Fiware-ServicePath": fiwareOptions.servicePath,
            },
          }
        );
        const entities = await results.json();
        for (const entity of entities) {
          entity.cameraType = this.defaultCameraType;
          delete entity.metadata;
          if (entity.address && entity.address.postalCode === null) {
            entity.address.postalCode = undefined;
          }
          entity.icon = entity.icon || {
            iconClass: "mdi-video",
            color: "#3c72ff",
          };
        }
        this.$set(this.entities, provider, entities);
      } catch (error) {
        this.$set(this.entities, provider, []);
      }
      this.loading = false;
    },
  },
  async mounted() {
    const themeEl = document.getElementById("theme") as HTMLLinkElement & {
      setTheme: () => void;
    };
    this.$store.commit("setValue", [
      "isDarkTheme",
      themeEl.getAttribute("theme-name") === "dark",
    ]);
  },
});
