(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("primevue/inputtext"), require("primevue/textarea"), require("primevue/datatable"), require("primevue/column"), require("primevue/inputmask"), require("primevue/tooltip"), require("single-spa-vue"), require("primevue/toastservice"), require("vue-i18n"), require("vue"), require("primevue/dataview"), require("@netvision/lib-api-gateway"), require("primevue/colorpicker"), require("primevue/toast"), require("primevue/progressbar"), require("primevue/tabview"), require("primevue/dialog"), require("primevue/button"), require("primevue/tabpanel"));
	else if(typeof define === 'function' && define.amd)
		define(["primevue/inputtext", "primevue/textarea", "primevue/datatable", "primevue/column", "primevue/inputmask", "primevue/tooltip", "single-spa-vue", "primevue/toastservice", "vue-i18n", "vue", "primevue/dataview", "@netvision/lib-api-gateway", "primevue/colorpicker", "primevue/toast", "primevue/progressbar", "primevue/tabview", "primevue/dialog", "primevue/button", "primevue/tabpanel"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("primevue/inputtext"), require("primevue/textarea"), require("primevue/datatable"), require("primevue/column"), require("primevue/inputmask"), require("primevue/tooltip"), require("single-spa-vue"), require("primevue/toastservice"), require("vue-i18n"), require("vue"), require("primevue/dataview"), require("@netvision/lib-api-gateway"), require("primevue/colorpicker"), require("primevue/toast"), require("primevue/progressbar"), require("primevue/tabview"), require("primevue/dialog"), require("primevue/button"), require("primevue/tabpanel")) : factory(root["primevue/inputtext"], root["primevue/textarea"], root["primevue/datatable"], root["primevue/column"], root["primevue/inputmask"], root["primevue/tooltip"], root["single-spa-vue"], root["primevue/toastservice"], root["vue-i18n"], root["vue"], root["primevue/dataview"], root["@netvision/lib-api-gateway"], root["primevue/colorpicker"], root["primevue/toast"], root["primevue/progressbar"], root["primevue/tabview"], root["primevue/dialog"], root["primevue/button"], root["primevue/tabpanel"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__025a__, __WEBPACK_EXTERNAL_MODULE__12bc__, __WEBPACK_EXTERNAL_MODULE__20c0__, __WEBPACK_EXTERNAL_MODULE__3724__, __WEBPACK_EXTERNAL_MODULE__392e__, __WEBPACK_EXTERNAL_MODULE__3b23__, __WEBPACK_EXTERNAL_MODULE__4171__, __WEBPACK_EXTERNAL_MODULE__68f9__, __WEBPACK_EXTERNAL_MODULE__85b3__, __WEBPACK_EXTERNAL_MODULE__8bbf__, __WEBPACK_EXTERNAL_MODULE__94a0__, __WEBPACK_EXTERNAL_MODULE_bb6b__, __WEBPACK_EXTERNAL_MODULE_d142__, __WEBPACK_EXTERNAL_MODULE_d26e__, __WEBPACK_EXTERNAL_MODULE_d833__, __WEBPACK_EXTERNAL_MODULE_dbbc__, __WEBPACK_EXTERNAL_MODULE_dec9__, __WEBPACK_EXTERNAL_MODULE_f095__, __WEBPACK_EXTERNAL_MODULE_f364__) {
return 