






import Vue from "vue";
import { mapState } from "vuex";
import Textarea from "primevue/textarea";
export default Vue.extend({
  name: "TextField",
  components: {
    Textarea,
  },
  props: {
    entity: Object,
    options: Object,
  },
  computed: {
    ...mapState(["strictRegEx"]),
    localValue: {
      set(val: string) {
        const entity = { ...this.entity };
        entity[this.options.title] = val.replace(this.strictRegEx, "");
        this.$emit("commit", entity);
      },
      get(): Object {
        return this.entity[this.options.title] || "";
      },
    },
  },
  data() {
    return {};
  },
});
