export const RUSSIAN_TRANSLATIONS = {
  button: {
    ok: "ОК",
    yes: "Да",
    no: "Нет",
    delete: "Удалить",
    save: "Сохранить",
    saveView: "Сохранить вид",
    create: "Создать",
    cancel: "Отмена",
    change: "Изменить",
    add: "Добавить",
    deleteAll: "Очистить все",
    openAdminDialog: "Полный редактор",
    import: "Импортировать",
  },
  headers: {
    address: "Адрес",
    title: "Имя",
    cameraType: "Тип камеры",
    location: "Локация",
    icon: "",
    import: "Результат импорта",
  },
  message: {
    empty: "Камер для импорта нет",
  },
  form: {
    addressLocality: "Населенный пункт",
    postalCode: "Почтовый индекс",
    postalCodeMask: "999999",
    streetAddress: "Адрес",
    format: "Формат",
    title: "Название",
    description: "Описание",
    user: "Пользователь",
    addUser: "Добавить пользователя",
    group: "Группа",
    findGroup: "Найти группу",
    scopes: "Действия",
    unknownValue: "Неизвестное значение",
    all: "Все",
    positive: "Разрешить",
    negative: "Запретить",
    emptyGroupsList: "Нет пользователей в группе",
    unnamed: "Безымянный",
    choose: "Выбрать",
    or: "Или",
    color: "Цвет",
    unexpectedError: "Возникла непредвиденная ошибка",
    successImported: "Успешно импортировано",
    search: "Поиск",
  },
};
