






import Vue from "vue";
import ImportCameras from "./components/ImportCameras.vue";

export default Vue.extend({
  name: "App",
  components: {
    ImportCameras,
  },
  data() {
    const props = (this.$parent?.props as WidgetOptions) || {};
    return {
      props,
      themeUnsubscribe: () => {},
    };
  },
  provide(): any {
    return {
      defaultMapLocation:
        this.$parent?.props.defaultMapLocation || "53.20017221, 50.12033860",
    };
  },
  mounted() {
    const themeEl = document.getElementById("theme") as HTMLLinkElement & {
      setTheme: () => void;
    };
    this.$store.commit("setValue", [
      "isDarkTheme",
      themeEl.getAttribute("theme-name") === "dark",
    ]);
    const themeSubscribe = (func: (newValue: boolean) => void) => {
      const listener = (e: any) => func(e.detail === "dark");
      themeEl.addEventListener("update", listener);
      return () => themeEl.removeEventListener("update", listener);
    };
    this.themeUnsubscribe = themeSubscribe((newValue) => {
      this.$store.commit("setValue", ["isDarkTheme", newValue]);
    });
  },
  destroyed() {
    this.themeUnsubscribe();
  },
});
