









import Vue from "vue";

export default Vue.extend({
  name: "AddressText",
  props: {
    field: Object,
  },
  data() {
    return {};
  },
});
