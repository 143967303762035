





import Vue from "vue";
import { findRealParent } from "@/utils";
//@ts-ignore
import { VectorLayer } from "maptalks";

export default Vue.extend({
  name: "TLayer",
  props: {
    layerName: String,
    options: Object,
    visible: Boolean,
  },
  data() {
    return {
      layerComponent: {} as any,
    };
  },
  beforeMount() {
    const parentMap = findRealParent(this);
    this.layerComponent = new VectorLayer(this.layerName, [], {
      ...this.options,
    }).addTo(parentMap);
  },
  beforeDestroy() {
    this.layerComponent.remove();
  },
});
