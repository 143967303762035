




























































































import Vue from "vue";
import DataView from "primevue/dataview";
import MarkerCircle from "./MarkerCircle.vue";
import ColorPicker from "primevue/colorpicker";
import { debounce } from "@/utils";

//@ts-ignore
import { iconsList } from "./iconsList";
type Icon = {
  iconClass: string;
  color: string;
};
export default Vue.extend({
  name: "ImageField",
  components: {
    MarkerCircle,
    DataView,
    ColorPicker,
  },
  props: {
    entity: Object,
    options: Object,
  },
  data() {
    return {
      iconsDialog: false,
      iconsList,
      search: "",
    };
  },
  computed: {
    filteredIcons(): string[] {
      return iconsList.filter((iconClass: string) =>
        iconClass.includes(this.search.toLowerCase())
      );
    },
    color: {
      set(val: string) {
        if (!/^#/.test(val)) {
          val = "#" + val;
        }
        if (/^#(?:[0-9a-fA-F]{3}){1,2}$/.test(val)) {
          debounce(() => {
            this.localValue = { ...this.localValue, color: val };
          }, 200);
        }
      },
      get(): string {
        return this.localValue.color;
      },
    },
    localValue: {
      set(val: Icon) {
        const entity = { ...this.entity };
        entity[this.options.title] = val;
        this.$emit("commit", entity);
      },
      get(): Icon {
        return (
          this.entity[this.options.title] || {
            iconClass: "mdi-video",
            color: "#005AD1",
          }
        );
      },
    },
  },
  methods: {
    changeIcon(icon: string) {
      this.localValue = { ...this.localValue, iconClass: icon };
      this.iconsDialog = false;
    },
  },
  beforeMount() {
    if (this.entity[this.options.title] === undefined) {
      this.entity[this.options.title] = {
        iconClass: "mdi-video",
        color: "#3c72ff",
      };
    }
  },
});
