










































import Vue from "vue";
import { mapState } from "vuex";
export default Vue.extend({
  name: "MarkerCircle",
  props: {
    color: String,
    haveLeg: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["isDarkTheme"]),
  },
});
